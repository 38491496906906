<template>
    <div>
        <el-card class="box-card card-contanier">
            <div class="card-content">
            </div>
            <div class="card-content">
                <el-form :inline="true" @submit.native.prevent>
                    <el-row>
                        <el-col :span="22">
                            <div style="float:left;width: 90%">
                                <el-form-item v-if="level === '商盟'" label="所属联盟:">
                                    <el-select
                                        v-model="searchForm.alId"
                                        placeholder="请选择"
                                        @change="allianceChange(searchForm.alId)"
                                    >
                                        <el-option
                                        v-for="item in alIdOptions"
                                        :key="item.al_id"
                                        :label="item.al_name"
                                        :value="item.al_id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-if="level === '商盟'" label="所属商户:">
                                    <el-select
                                        v-model="merchants"
                                        placeholder="请选择"
                                        @change="merchantsChange(merchants)"
                                    >
                                        <el-option label="请选择" :value="0">--请选择--</el-option>
                                        <el-option
                                        v-for="item in meIdOptions"
                                        :key="item.me_id"
                                        :label="item.me_name"
                                        :value="item.me_id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-if="level === '商盟'" label="所属门店:">
                                    <el-select  v-model="store" placeholder="请选择">
                                        <el-option label="请选择" :value="0">--请选择--</el-option>
                                        <el-option
                                        v-for="item in soeIdOptions"
                                        :key="item.soe_id"
                                        :label="item.soe_name"
                                        :value="item.soe_id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="卡卷名称:">
                                    <el-input v-model="searchForm.cardName" placeholder="卡卷名称" @keyup.enter.native="search"></el-input>
                                </el-form-item>
                                <el-form-item label="领取起始时间:">
                                    <el-date-picker
                                        v-model="searchForm.offcUseDm"
                                        type="daterange"
                                        align="right"
                                        unlink-panels
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        :picker-options="pickerOptions">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-button @click="search" icon="el-icon-search" size="medium">搜索</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-button @click="refresh" icon="el-icon-refresh" size="medium">重置</el-button>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item>
                                <el-button v-if="$route.query && $route.query.couBatch" size="medium" icon="el-icon-back" @click="() => { this.$router.go(-1) }">返回</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="代金卷" name="first">
                <el-table
                    ref="multipleTable"
                    :data="tableData"
                    v-loading="giftTableLoading"
                    tooltip-effect="dark"
                    style="width: 100%"
                    size="mini"
                    border
                    stripe
                    >
                    <el-table-column type="index" label="序号" align="center">
                    </el-table-column>
                    <el-table-column prop="al_name" label="所属商盟" align="center">
                    </el-table-column>
                    <el-table-column prop="me_name" label="所属商户" align="center">
                    </el-table-column>
                    <el-table-column prop="soe_name" label="所属门店" align="center">
                    </el-table-column>
                    <el-table-column prop="cou_batch" label="卡劵批次" align="center">
                    </el-table-column>
                    <el-table-column prop="card_name" label="卡卷名称" align="center">
                    </el-table-column>
                    <el-table-column prop="rec_count" label="发放张数" align="center">
                    </el-table-column>
                    <el-table-column prop="overdm" label="过期张数" align="center">
                    </el-table-column>
                    <el-table-column prop="writeOff" label="核销张数" align="center">
                    </el-table-column>
                    <el-table-column prop="rec_sy" label="剩余张数" align="center">
                    </el-table-column>
                    <el-table-column
                    prop="icon"
                    label="操作"
                    width="100"
                    align="center"
                    fixed="right"
                >
                <template slot-scope="scope">
                    <!-- <el-button :disabled="scope.row.gift_use_type !== 0" type="text" @click="lqjck(scope.row)">领取</el-button> -->
                    <!-- <el-divider direction="vertical"></el-divider> -->
                    <el-button type="text" @click="menberDetails(scope.row.cou_batch,'代金卷')">领取明细</el-button>
                    </template>
                </el-table-column>
                </el-table>
                <div class="block">
                    <el-pagination
                        style="margin-top: 20px;text-align: right;"
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10,20,50,100]"
                        :current-page="current"
                        :page-size="size"
                        :total="total"
                         ></el-pagination>
                </div>

            </el-tab-pane>
            <el-tab-pane label="计次卡" name="second">
                <el-table
                    ref="multipleTable"
                    :data="timeCardData"
                    v-loading="timeTableLoading"
                    tooltip-effect="dark"
                    style="width: 100%"
                    size="mini"
                    border
                    stripe
                    >
                    <el-table-column type="index" label="序号" align="center">
                    </el-table-column>
                    <el-table-column prop="al_name" label="所属商盟" align="center">
                    </el-table-column>
                    <el-table-column prop="me_name" label="所属商户" align="center">
                    </el-table-column>
                    <el-table-column prop="soe_name" label="所属门店" align="center">
                    </el-table-column>
                    <el-table-column prop="cou_batch" width="150" label="卡卷批次" align="center">
                    </el-table-column>
                    <el-table-column prop="card_name" label="卡卷名称" align="center">
                    </el-table-column>
                    <el-table-column prop="rec_count" label="发放张数" align="center">
                    </el-table-column>
                    <el-table-column prop="overdm" label="过期张数" align="center">
                    </el-table-column>
                    <el-table-column prop="sri_oof_count" label="核销次数" align="center">
                    </el-table-column>
                    <el-table-column prop="sri_surp_count" label="剩余张数" align="center">
                    </el-table-column>
                    <el-table-column
                    prop="icon"
                    label="操作"
                    width="100"
                    align="center"
                    fixed="right"
                    >
                        <template slot-scope="scope">
                            <!-- <el-button :disabled="scope.row.gift_use_type !== 0" type="text" @click="lqjck(scope.row)">领取</el-button> -->
                            <!-- <el-divider direction="vertical"></el-divider> -->
                            <el-button type="text" @click="menberDetails(scope.row.cou_batch,'计次卡')">领取明细</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="block">
                    <el-pagination
                        style="margin-top: 20px;text-align: right;"
                        background
                        @size-change="timeCardSizeChange"
                        @current-change="timeCardCurrentChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10,20,50,100]"
                        :current-page="timeCardCurrent"
                        :page-size="timeCardSize"
                        :total="timeCardTotal"
                ></el-pagination>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!--会员详情-->
        <el-dialog :title="title+'-领取明细'" :visible.sync="detailsDialog"
            width="70%"
        :before-close="detailsClose"
        :close-on-click-modal="false">
            <el-table
                ref="multipleTable"
                :data="tableDatails"
                tooltip-effect="dark"
                border
                style="width: 100%"
                stripe
                size="small"
                :row-style="{height:'10px'}"
                :cell-style="{padding:'1px 0'}"
                row-key="sri_card_num"
                @expand-change="expandChange"
            >
                <!--<el-table-column type="selection" width="55" align="center"></el-table-column>-->
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-table
                                :data="props.row.jckDataList"
                                v-loading="props.row.jclLoading"
                                border
                                size="small"
                                style="font-size: 12px;">
                            <el-table-column label="序号" width="50" align="center">
                                <template slot-scope="scop">
                                    {{scop.$index+1}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="soe_name" align="center" label="消费门店"></el-table-column>
                            <el-table-column label="核销方式" show-overflow-tooltip align="center">
                                <template slot-scope="scope">
                                    <el-tag size="small" v-if="scope.row.offc_type === 1">卡号</el-tag>
                                    <el-tag type="danger" size="small" v-else-if="scope.row.offc_type === 2">二维码</el-tag>
                                    <el-tag type="warning" size="small" v-else-if="scope.row.offc_type === 3">条形码</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="使用时间" width="170" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.offc_use_dm ? scope.row.offc_use_dm.replaceAll('T', ' ') : '' }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="offc_order_no" align="center" label="消费订单"></el-table-column>
                            <el-table-column prop="offc_off_order" align="center" label="核销订单"></el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <!-- <el-table-column prop="pvipId" label="会员id" show-overflow-tooltip align="center"></el-table-column> -->
                <el-table-column prop="pvip_nickname"  label="会员昵称" show-overflow-tooltip align="center">
                </el-table-column>
                <el-table-column prop="pvip_phone" width="150" label="手机号" show-overflow-tooltip align="center">
                </el-table-column>
                <el-table-column prop="me_name" label="所属商户" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="pvip_level" label="会员等级" show-overflow-tooltip align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" size="small" v-if="scope.row.pvip_level === 0">普通会员</el-tag>
                        <el-tag type="success" size="small" v-else-if="scope.row.pvip_level === 1">黄金会员</el-tag>
                        <el-tag type="danger" size="small" v-else-if="scope.row.pvip_level === 2">白金会员</el-tag>
                        <!-- <div type="text"  @click="editHandler(scope.row)">
                        <a style="color:black" href="#">{{scope.row.pvipLevel}}</a>
                        </div> -->
                    </template>
                </el-table-column>
                <el-table-column prop="rec_dm" width="150" label="领取时间" show-overflow-tooltip align="center"></el-table-column>
                <!-- <el-table-column prop="rec_type" label="卡券类型" show-overflow-tooltip align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" size="small" v-if="scope.row.rec_type === 0">代金卷</el-tag>
                        <el-tag type="danger" size="small" v-else-if="scope.row.rec_type === 1">计次卡</el-tag>
                    </template>
                </el-table-column> -->
                <el-table-column prop="rec_styp" label="领取方式" show-overflow-tooltip align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" size="small" v-if="scope.row.rec_styp === 1">推送领取</el-tag>
                        <el-tag type="danger" size="small" v-else-if="scope.row.rec_styp === 2">链接</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="rec_count" label="领取张数" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="overdm" label="过期张数" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="sri_oof_count" :label="title === '代金卷' ? '核销张数' :'核销次数'" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="sri_surp_count" :label="title === '代金卷' ? '剩余张数' :'剩余张数'" show-overflow-tooltip align="center"></el-table-column>
            </el-table>
            <div>
                <el-pagination
                    background
                    @size-change="detailsSizeChange"
                    @current-change="detailsCurrentChange"
                    :current-page="detailsCurrent"
                    :page-sizes="[10,20,50,100]"
                    :page-size="detailsSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="detailsTotal"
                ></el-pagination>
            </div>
            <div slot="footer" class="dialog-footer">

            </div>
        </el-dialog>
    </div>
</template>
<script>
    import api from "../../api/timeCard";
    import sys from "@/api/sysUser";
    import user from "../../api/user";
    import gift from "../../api/giftCard";
    export default {
        name: "AssetType",
        data() {
            return {
                oneGiftCouBatch: this.$route.query.type === 'gift' ? this.$route.query.couBatch : '',//查单一批次，即某张代金券的
                oneTimeCouBatch: this.$route.query.type === 'second' ? this.$route.query.couBatch : '',//查单一批次，即某张计次卡的
                giftTableLoading: false,
                timeTableLoading: false,
                level:'',
                total: 0,
                size: 10,
                current: 1,
                tableData: [],
                timeCardTotal: 0,
                timeCardSize: 10,
                timeCardCurrent: 1,
                detailsTotal: 0,
                detailsSize: 10,
                detailsCurrent: 1,
                timeCardData: [],
                tableDatails:[],
                activeName: this.$route.query && this.$route.query.type === 'second' ? 'second' : 'first',
                sendContent:'',
                personnelData:[],
                searchForm:{},
                detailsDialog:false,//会员详情对话框
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                        }
                    }],
                },
                alIdOptions: [], //联盟选择框
                meIdOptions: [], //商户选择框
                soeIdOptions: [], //所属门店
                merchants:0,
                store: 0,
                couBatch:'',//批次
                title:''
            };
        },
        watch: {
            $route(to, from){//监听地址栏变动，否则 点了修改，又点了菜单会出问题
                if(to.path === '/timeCard/cardRoll'){//查询单个变为了查询某一个批次的
                    this.oneGiftCouBatch = '';
                    this.oneTimeCouBatch = '';
                    this.getList();
                    this.getTimeCardList();
                }
            },
        },
        created() {
            //this.getUserInfo();
            this.getList();
            //this.getlistAlliance();
            this.getTimeCardList();
        },
        methods: {
            getUserInfo() {
                user.userinfo().then(res => {
                    if(res.data.data.result.soeId){
                        this.level = "门店"
                    }else if(res.data.data.result.meId){
                        this.level = "商户"
                    }else if(res.data.data.result.alId){
                        this.level = "商盟"
                    }else{
                        this.level = "平台"
                    }
                    //this.userInfo = res.data.data.result;
                });
            },
            refresh() {
                this.searchForm = {}
                this.current = 1;
                this.getList();
                this.getTimeCardList();
            },
            search(){
                this.current = 1;
                this.getList();
                this.getTimeCardList();
                if(this.title === '计次卡'){
                    api.getCardDatails(this.couBatch,this.detailsCurrent,this.searchForm).then(res =>{
                        this.tableDatails = res.data.data.result.records;
                        this.detailsSize = res.data.data.result.size;
                        //this.current = res.data.data.result.current;
                        this.detailsTotal = res.data.data.result.total;
                    })
                }else if(this.title === '代金卷'){
                    api.getStatVouchDatails(this.couBatch,this.detailsCurrent,this.searchForm).then(res =>{
                        this.tableDatails = res.data.data.result.records;
                        
                        this.detailsSize = res.data.data.result.size;
                        this.current = res.data.data.result.current;
                        this.detailsTotal = res.data.data.result.total;
                    })
                }
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            //列表数据查询
            getList() {
                this.merchants,this.store,this.store,this.searchForm
                this.giftTableLoading = true;
                api.getStatVoucherList(this.current,this.merchants,this.store,this.searchForm,this.oneGiftCouBatch,this.size).then(res => {
                    this.tableData = res.data.data.result.records;
                    this.size = res.data.data.result.size;
                    //this.current = res.data.data.result.current;
                    this.total = res.data.data.result.total;
                    this.giftTableLoading = false;
                })
            },
            getTimeCardList(){
                this.timeTableLoading = true;
                api.getStatTimeCard(this.timeCardCurrent,this.merchants,this.store,this.searchForm,this.oneTimeCouBatch,this.timeCardSize).then(res =>{
                    this.timeCardData = res.data.data.result.records;
                    this.timeCardSize = res.data.data.result.size;
                    //this.current = res.data.data.result.current;
                    this.timeCardTotal = res.data.data.result.total;
                    this.timeTableLoading = false;
                })
            },
            menberDetails(couBatch,title){
                this.title = title;
                this.couBatch = couBatch;
                this.detailsDialog = true;
                if(title === '计次卡'){
                    api.getCardDatails(couBatch,this.detailsCurrent,this.detailsSize,this.searchForm).then(res =>{
                        this.tableDatails = res.data.data.result.records;
                        this.detailsSize = res.data.data.result.size;
                        //this.current = res.data.data.result.current;
                        this.detailsTotal = res.data.data.result.total;
                    })
                }else if(title === '代金卷'){
                    api.getStatVouchDatails(couBatch,this.detailsCurrent,this.detailsSize,this.searchForm).then(res =>{
                        this.tableDatails = res.data.data.result.records;
                        console.log(this.tableDatails)
                        this.detailsSize = res.data.data.result.size;
                        this.current = res.data.data.result.current;
                        this.detailsTotal = res.data.data.result.total;
                    })
                }

            },
            handleSizeChange(val) {
                this.size = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.current = val;
                this.getList();
            },
            timeCardSizeChange(val) {
                this.timeCardSize = val;
                this.getTimeCardList();
            },
            timeCardCurrentChange(val) {
                this.timeCardCurrent = val;
                this.getTimeCardList();
            },
            detailsSizeChange(val) {
                this.detailsSize = val;
                this.menberDetails(this.couBatch,this.title);
            },
            detailsCurrentChange(val) {
                this.detailsCurrent = val;
                this.menberDetails(this.couBatch,this.title);
            },
            personneList(row){
                api.getFailedList(row.segId).then(res =>{
                   this.personnelData = res.data.data.result;
                })
            },
            // getlistAlliance() {
            //     sys.getlistAlliance().then((res) => {
            //         this.alIdOptions = res.data.data.result;
            //     });
            // },
            // merchantsChange(meId) {
            //     sys.getListSyStore(meId).then((res) => {
            //         this.soeIdOptions = res.data.data.result;
            //         this.store = 0;
            //     });
            // },
            // allianceChange(alId) {
            //     sys.getListMerchants(alId).then((res) => {
            //         this.meIdOptions = res.data.data.result;
            //         this.merchants = 0;
            //         this.store = 0;
            //     });
            // },
            detailsClose(){
                this.detailsDialog = false;
            },
            // 获取父层表格数据
            /*_getAllRules (key, value) {
                this.tableLoading = true;
                const data = { method: 'find_rule_bases', [key]: value };
                this.getAllRules({ data }).then((res) => {
                    if (res.code === 0) {
                        this.allRules = JSON.parse(res.result);
                        // 获取到父层表格数据之后,遍历添加唯一id(如果有则不用加,可由后台返回)和子层表格数据
                        this.allRules.map((item, index) => {
                            item.keyId = index;
                            item.childrenData = []; // 添加子表格数据
                        });
                    }
                    this.tableLoading = false;
                }, (err) => {
                    console.log(err);
                    this.allRules = [];
                    this.tableLoading = false;
                });
            },*/
            // 获取子层表格数据并更新allRules数据
            expandChange(row, expandedRows) {
                if (!row.children) {
                    // 通过$set属性可设置loading实现实时加载loading效果(经过测试,通过$set直接给父层数据声明子层数据时会出现报错,所以才在获取父层表格数据时声明子层表格数据)
                    this.$set(row, 'jclLoading', true);
                    gift.hxmxList({
                        params: {
                            cardNum: row.sri_card_num,
                        },
                    })
                    .then((res) => {
                        // 遍历父层表格数据
                        this.tableDatails.forEach((temp, index) => {
                            // 找到当前展开的行，把获取到的数据赋值进去
                            if (temp.sri_card_num === row.sri_card_num) {
                                this.tableDatails[index].jckDataList = res.data.data.result || [];
                                this.$set(row, 'jclLoading', false);
                            }
                        });
                    });
                }
            },
        }
    };
</script>
